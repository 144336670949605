import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const CaseStudyCardStyles = styled.div`
  height: 100%;
  background-color: ${colors.base.white};
  padding: 0;
  border-radius: 12px;
  ${shadow('xl')}
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
`;

export const ImageContainer = styled.div`
  flex: 0 0 auto;

  .gatsby-image-wrapper {
    width: 100%;
    height: 290px;
    display: block;
    overflow: hidden;

    img {
      display: block;
      object-fit: cover;
      object-position: center;
      transform-origin: center;
      transition: transform 300ms ease-in-out 0s;

      ${CaseStudyCardStyles}:hover & {
        transform: scale(1.1);
      }
    }
  }
`;

export const ContentContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 44px;
  width: 100%;
  padding: 24px;

  .excerpt {
    ${font('text', 'sm', '400')};
    color: ${colors.gray[700]};
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const Heading = styled.h3`
  ${font('display', 'sm', '700')};
  color: ${colors.gray[900]};
  margin-bottom: 12px;
`;

export const MetaLabel = styled.div`
  ${font('text', 'xs', '400')};
  color: ${colors.gray[600]};
  white-space: nowrap;
  padding-left: 16px;
`;
